import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import Login from "../pages/auth/login";
import Dashboard from "../pages/dashboard/supr-admin-dashboard";
import LoggedInRoute from "./loggedInRoutes";
import LoggedOutRoute from "./loggedOutRoutes";
import ForgotPassword from "../pages/auth/forgot-password/ForgotPassword";
import Hospitalform from "../pages/doctor-form";
import AddAdmin from "../pages/add-admin";
import SuperAdminHospitalDetail from "../pages/super-admin-hospital-detail";
import AppNetwork from "../pages/app-network";
import SuperAdminAddPatient from "../pages/super-admin-add-patient";
import PatientDetail from "../pages/patient-detail/PatientDetail";
import SuperAdminHeartRate from "../pages/super-heart-rate/SuperAdminHeartRate";
import TwilioCall from "../pages/dashboard/Twilio-call";
import DoctorCard from "../pages/doctor-card/DoctorCard";
import AppNetworkPatientList from "../pages/app-network-patient/AppNetworkPatientList";
import AdminDashboard from "../pages/dashboard/admin-dashboard/AdminDashboard";
import AdminHeartRate from "../pages/admin-heart-rate/AdminHeartRate";
import AdminAddPatient from "../pages/admin-add-patient";
import AppNetworkHeartRate from "../pages/app-network-patient/app-network-heart-rate/AppNetworkHeartRate";
import AdminStepCount from "../pages/dashboard/StepCountComponents/AdminStepCount";
import AdminBloodOxygen from "../pages/dashboard/BloodOxygenComponent/AdminBloodOxygen";
import AppNetworkStepCount from "../pages/dashboard/StepCountComponents/AppNetworkStepCount";
import SuperAdminStepCount from "../pages/dashboard/StepCountComponents/SuperAdminStepCount";
import SuperAdminBloodOxygen from "../pages/dashboard/BloodOxygenComponent/SuperAdminBloodOxygen";
import AppNetworkBloodOxygen from "../pages/dashboard/BloodOxygenComponent/AppNetworkBloodOxygen";
import DailyTask from "../pages/dashboard/daily-task/DailyTask";
import MedicineManagement from "../pages/dashboard/medicine-management/MedicineManagement";
import Appointments from "../pages/dashboard/appointments/Appointments";
import Notes from "../pages/dashboard/Notes/Notes";
import DeletePatinent from "../pages/dashboard/Delete Patinent/DeletePatinent";
import AddAdminForm from "../pages/auth/AddAdminForm/AddAdminForm";
import AddPatient from "../pages/addPatient/AddPatient";
import Calender from "../pages/dashboard/Calender/Calender";
import PatientDetails from "../pages/dashboard/Patient Details/PatientDetails";
import CallLog from "../pages/dashboard/Patient Details/CallLog";
import AddCareGiver from "../pages/dashboard/Patient Details/AddCareGiver";
import CareGiversList from "../pages/dashboard/Patient Details/CareGiversList";
import axios from "axios";
import { API_BASE_PATH, SOCKET_CALL_PATH } from "../constants/workerConstants";
import PatientProfile from "../pages/dashboard/Patient Details/PatientProfile";
import AdminProfile from "../pages/dashboard/admin-profile/AdminProfile";
import Chat from "../pages/dashboard/Patient Details/Chat";
import MediaGalleryPage from "../pages/MediaGalleryPage/MediaGalleryPage";
import DashboardPatinentProfile from "../pages/dashboard/PatinentProfile/DashboardPatinentProfile";
import MemberDetails from "../pages/dashboard/MemberDetails/MemberDetails";
import PatientMemberList from "../pages/PatientDashboard/PatientMemberList";
import PatientVitalsPage from "../pages/PatientVitalsPage/PatientVitalsPage";
import { AppActionTypes } from "../redux/action-types";

const AppRoutes = (props) => {
  const ws = useRef(null);

  // WebSocket connection (for calling functionality)
  const connectCallWebsocket = () => {
    ws.current = new WebSocket(SOCKET_CALL_PATH, [
      "Authorization",
      props.authToken,
    ]);

    ws.current.onopen = (e) => {
      // console.log("WebSocket connection established", e);
    };

    ws.current.onmessage = (event) => {
      const message = JSON.parse(event.data); // this is = {data: {docs: [{}]}}
      console.log("Message received:", message);
      if (message && message.notificationType === "call") {
        // setIncomingCall(true);
        props.updateIncomingCall(true);
      }
    };

    ws.current.addEventListener("message", (event) => {
      const messages = JSON.parse(event.data);
      // console.log("addEventListener message==", messages);
    });
  };

  // calling WebSocket connection
  useEffect(() => {
    // return;
    if (!props.authToken) return;
    connectCallWebsocket();

    const interval = setInterval(() => {
      if (ws.current && ws.current.readyState !== WebSocket.OPEN) {
        connectCallWebsocket();
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [props.authToken]);

  function getMemberList(authToken) {
    axios
      .get(`${API_BASE_PATH}/user/getMemberList`, {
        headers: {
          authorization: authToken,
        },
      })
      .then((resp) => {
        if (resp.status === 200) {
          console.log("memersList:", resp.data.result);
          props.getMemberList(resp.data.result);
        }
      })
      .catch((err) => {
        console.log("getMemberList error", err);
      });
  }

  useEffect(() => {
    if (props.authToken) getMemberList(props.authToken);
  }, [props.authToken]);

  return (
    <Switch>
      <Route path="/" exact={true} component={Login} />
      <Route path="/auth/login" exact={true} component={Login} />
      <Route path="/forgot_password" exact={true} component={ForgotPassword} />
      <Route path="/addadminform" exact={true} component={AddAdminForm} />
      <Route path="/addpatient" exact={true} component={AddPatient} />
      <LoggedOutRoute path="/" exact={true} component={Login} />
      <LoggedOutRoute path="/auth/login" exact={true} component={Login} />
      <LoggedOutRoute
        path="/forgot_password"
        exact={true}
        component={ForgotPassword}
      />

      <LoggedOutRoute
        path="/addadminform"
        exact={true}
        component={AddAdminForm}
      />
      <LoggedInRoute path="/dashboard" exact={true} component={Dashboard} />
      <LoggedInRoute
        path="/deletepatinent"
        exact={true}
        component={DeletePatinent}
      />
      <LoggedInRoute
        path="/adminprofile"
        exact={true}
        component={AdminProfile}
      />

      {props.userData && props.userData.userRole === 3 ? (
        <>
          <LoggedInRoute
            path="/doctorcard"
            exact={true}
            component={DoctorCard}
          />
          <LoggedInRoute
            path="/doctorform"
            exact={true}
            component={Hospitalform}
          />
          <LoggedInRoute path="/addadmin" exact={true} component={AddAdmin} />
          <LoggedInRoute
            path="/superadminhospitaldetail"
            exact={true}
            component={SuperAdminHospitalDetail}
          />
          <LoggedInRoute
            path="/appnetwork"
            exact={true}
            component={AppNetwork}
          />
          <LoggedInRoute
            path="/patientlist"
            exact={true}
            component={AppNetworkPatientList}
          />
          <LoggedInRoute
            path="/superadminaddpatient"
            exact={true}
            component={SuperAdminAddPatient}
          />

          <LoggedInRoute
            path="/superadminhospitaldetail/patientdetail"
            exact={true}
            component={PatientDetail}
          />
          <LoggedInRoute
            path="/superadminhospitaldetail/patientdetail/superadminheartrate"
            exact={true}
            component={SuperAdminHeartRate}
          />
          <LoggedInRoute
            path="/superadminhospitaldetail/patientdetail/superadminstepcount"
            exact={true}
            component={SuperAdminStepCount}
          />
          <LoggedInRoute
            path="/superadminhospitaldetail/patientdetail/superadminbloodoxygen"
            exact={true}
            component={SuperAdminBloodOxygen}
          />

          <LoggedInRoute
            path="/appnetwork/appnetworkheartrate"
            exact={true}
            component={AppNetworkHeartRate}
          />
          <LoggedInRoute
            path="/appnetwork/appnetworkstepcount"
            exact={true}
            component={AppNetworkStepCount}
          />
          <LoggedInRoute
            path="/appnetwork/appnetworkbloodoxygen"
            exact={true}
            component={AppNetworkBloodOxygen}
          />
        </>
      ) : props.userData && props.userData.userRole === 1 ? (
        /// patient
        <>
          {/* <LoggedInRoute
            path="/patient/addcaregiver"
            exact={true}
            component={AddCareGiver}
          /> */}
          <LoggedInRoute
            path="/profile"
            exact={true}
            component={DashboardPatinentProfile}
          />
          <LoggedInRoute
            path="/dashboard/member-details/:id"
            exact={true}
            component={MemberDetails}
          />
          <LoggedInRoute
            path="/member-list"
            exact={true}
            component={PatientMemberList}
          />
          <LoggedInRoute
            path="/member-profile/:id"
            exact={true}
            component={PatientProfile}
          />
          <LoggedInRoute path="/:id/notes" exact={true} component={Notes} />
          <LoggedInRoute
            path="/:id/dailytask"
            exact={true}
            component={DailyTask}
          />
          <LoggedInRoute
            path="/:id/medicinemanagement"
            exact={true}
            component={MedicineManagement}
          />
          <LoggedInRoute
            path="/:id/appointments"
            exact={true}
            component={Appointments}
          />
          <LoggedInRoute
            path="/:id/calendar"
            exact={true}
            component={Calender}
          />
          <LoggedInRoute
            path="/:id/call-log"
            exact={true}
            component={CallLog}
          />
          <LoggedInRoute
            path="/:id/media-gallery"
            exact={true}
            component={MediaGalleryPage}
          />
          <LoggedInRoute
            path="/:id/addcaregiver"
            exact={true}
            component={AddCareGiver}
          />
          <LoggedInRoute path="/:id/chat" exact={true} component={Chat} />
          <LoggedInRoute
            path="/:id/caregiverslist"
            exact={true}
            component={CareGiversList}
          />
          <LoggedInRoute
            path="/patient/vitals"
            exact={true}
            component={PatientVitalsPage}
          />
          <LoggedInRoute
            path="/heart-rate"
            exact={true}
            component={AdminHeartRate}
          />
          <LoggedInRoute
            path="/step-count"
            exact={true}
            component={AdminStepCount}
          />
          <LoggedInRoute
            path="/blood-oxygen"
            exact={true}
            component={AdminBloodOxygen}
          />
          <LoggedInRoute
            path="/dashboard/member-call"
            exact={true}
            component={TwilioCall}
          />
        </>
      ) : (
        <>
          <LoggedInRoute
            path="/dashboard/patient-details/:id"
            exact={true}
            component={PatientDetails}
          />
          <LoggedInRoute
            path="/dashboard/patient-call"
            exact={true}
            component={TwilioCall}
          />
          <LoggedInRoute
            path="/dashboard/patient-details/:id/patientprofile"
            exact={true}
            component={PatientProfile}
          />
          <LoggedInRoute
            path="/dashboard/patient-details/:id/call-log"
            exact={true}
            component={CallLog}
          />
          <LoggedInRoute
            path="/dashboard/patient-details/:id/media-gallery"
            exact={true}
            component={MediaGalleryPage}
          />
          <LoggedInRoute
            path="/dashboard/patient-details/:id/addcaregiver"
            exact={true}
            component={AddCareGiver}
          />
          <LoggedInRoute
            path="/dashboard/patient-details/:id/chat"
            exact={true}
            component={Chat}
          />
          <LoggedInRoute
            path="/dashboard/patient-details/:id/caregiverslist"
            exact={true}
            component={CareGiversList}
          />
          <LoggedInRoute
            path="/admindashboard"
            exact={true}
            component={AdminDashboard}
          />
          <LoggedInRoute
            path="/admindashboard/adminheartrate"
            exact={true}
            component={AdminHeartRate}
          />
          <LoggedInRoute
            path="/admindashboard/adminstepcount"
            exact={true}
            component={AdminStepCount}
          />
          <LoggedOutRoute
            path="/dashboard/patient-details/:id/notes"
            exact={true}
            component={Notes}
          />
          <LoggedOutRoute
            path="/dashboard/patient-details/:id/dailytask"
            exact={true}
            component={DailyTask}
          />
          <LoggedOutRoute
            path="/dashboard/patient-details/:id/medicinemanagement"
            exact={true}
            component={MedicineManagement}
          />
          <LoggedInRoute
            path="/dashboard/patient-details/:id/appointments"
            exact={true}
            component={Appointments}
          />
          <LoggedOutRoute
            path="/dashboard/patient-details/:id/calendar"
            exact={true}
            component={Calender}
          />
          <LoggedInRoute
            path="/admindashboard/adminbloodoxygen"
            exact={true}
            component={AdminBloodOxygen}
          />
          <LoggedInRoute
            path="/adminaddpatient"
            exact={true}
            component={AdminAddPatient}
          />
        </>
      )}
    </Switch>
  );
};

const mapStateToProps = (state) => {
  return {
    authReducer: state.authReducer,
    userData: state.persist.userData,
    authToken: state.persist.authToken,
    memberList: state.memberListReducer.memberList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getMemberList: (data) =>
      dispatch({
        type: "memberList/data",
        payload: data,
      }),

    updateIncomingCall: (data) =>
      dispatch({
        type: AppActionTypes.INCOMING_CALL_RING,
        payload: data,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppRoutes);
