import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import NavbarSuperAdmin from "../../../components/navbar/NavbarSuperAdmin";
import { GoToBack } from "../../../components/ui/GoToBack header/GoToBack";
import AddPatient from "../../addPatient/AddPatient";

const DashboardPatinentProfile = (props) => {
  console.log("props.userData", props.userData);
  const [patientData, setPatientData] = useState(null);

  useEffect(() => {
    let data;
    if (props.userData && props.userData?.network?.members?.length) {
      data = props.userData.network.members.find(
        (el) => el._id === props.userData._id
      );
      data = {
        ...data,
        networkName: props.userData?.network?.networkName,
      };

      if (props.userData.hospital) {
        data = {
          ...data,
          hospital: props.userData.hospital.hospitalName,
        };
      }
      if (props.userData.relationship) {
        data = {
          ...data,
          relationship: props.userData.relationship.name,
        };
      }
    }
    setPatientData(data);
  }, []);

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("persist:root"));
    const persist = JSON.parse(storedData.persist);
    if (persist.userData) {
      console.log("dashbaord persist", persist.userData);
      const data = {
        ...persist.userData,
        ...patientData,
        network: patientData?.network,
      };
      setPatientData(data);
    }
  }, [props.userData, props]);

  return (
    <div>
      <NavbarSuperAdmin />

      <div className="pl">
        <GoToBack>Your Profile</GoToBack>
        {patientData && (
          <AddPatient
            editProfile={true}
            isReadOnly={false}
            patientData={patientData}
            isTitle={false}
          />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.persist.userData,
    memberList: state.memberListReducer.memberList,
  };
};

export default connect(mapStateToProps)(DashboardPatinentProfile);
