import React, { useEffect, useRef } from "react";
import styles from "./IncomingCallRing.module.css";
import { Call, CallEnd } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { AppActionTypes } from "../../../redux/action-types";

const IncomingCallRing = ({
  callerName,
  roomName,
  callType,
  userData,
  updateIncomingCall,
}) => {
  const audioRef = useRef(null);
  const history = useHistory();

  useEffect(() => {
    if (!audioRef || !audioRef.current) return;

    setTimeout(() => {
      audioRef.current.play();
    }, 300);
  }, [audioRef, audioRef.current]);

  console.log("userData.userRole", userData.userRole);

  const onCallAccept = () => {
    // audioRef.current.pause();
    if (userData.userRole == 0) {
      history.push({
        pathname: `/dashboard/patient-call`,
      });
    } else if (userData.userRole === 1) {
      history.push({
        pathname: `/dashboard/member-call`,
      });
    }
    updateIncomingCall(false);
  };

  return (
    <>
      <div className={styles.container}>
        <audio ref={audioRef} loop>
          <source
            src="https://www.soundjay.com/phone/sounds/telephone-ring-02.mp3"
            type="audio/mpeg"
          />
        </audio>
        <div className={styles.callerInfo}>
          <p className={styles.callerName}>{callerName}</p>
          <p className={styles.roomName}>Room: {roomName}</p>
        </div>

        <div className={styles.callButtons}>
          <button className={styles.acceptButton} onClick={onCallAccept}>
            <Call />
          </button>
          <button
            onClick={() => {
              updateIncomingCall(false);
            }}
            className={styles.rejectButton}
          >
            <CallEnd />
          </button>
        </div>
      </div>
    </>
  );
};

export default IncomingCallRing;
